import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import LinkButton from "../../components/link-button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`If you've read about some of the ideas of longtermism, and you've found them compelling so far, you might enjoy getting involved in a more meaningful way. Below, we've listed some ideas.`}</p>
    <h2 {...{
      "id": "choosing-an-impactful-career",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#choosing-an-impactful-career",
        "aria-label": "choosing an impactful career permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Choosing an impactful career`}</h2>
    <p>{`If you’re fortunate enough to be able to use your career for good and you’re considering a new direction, or you’re currently considering what to do after university, the nonprofit `}<a parentName="p" {...{
        "href": "https://80000hours.org/"
      }}>{`80,000 Hours`}</a>{` exists to help you! 80,000 Hours offers free career guidance, and their website is an incredible resource for learning more about longtermist cause areas.`}</p>
    <LinkButton to='https://80000hours.org/' mdxType="LinkButton">Visit <b>80,000 Hours</b></LinkButton>
    <p>{`In particular, you would do well by reading this article first:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://80000hours.org/make-a-difference-with-your-career/"
        }}>{`This is your most important decision`}</a>{` — why your career is your biggest opportunity to make a difference, and how you can use it best.`}</li>
    </ul>
    <p>{`80,000 Hours also `}<a parentName="p" {...{
        "href": "https://80000hours.org/podcast/"
      }}>{`produce a podcast`}</a>{` featuring in-depth conversations about the world’s most pressing problems and what you can do to solve them — many of which focus on longtermist topics.`}</p>
    <p>{`Many longtermist organizations are hiring, or will be hiring soon. You can look for an opening on this `}<a parentName="p" {...{
        "href": "https://80000hours.org/job-board/"
      }}>{`job board`}</a>{`, which lists roles across the world which present especially promising opportunities to improve the long-term future.`}</p>
    <p>{`You can apply for one-on-one career guidance from 80,000 Hours `}<a parentName="p" {...{
        "href": "https://80000hours.org/speak-with-us"
      }}>{`here`}</a>{`.`}</p>
    <p>{`If you have an idea for a project that could make progress on a longtermist cause area — such as conducting a piece of research, or starting a student group — then you might be able to get support from the `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/funds/far-future"
      }}>{`Long-Term Future Fund`}</a>{`, which makes grants on behalf of `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/"
      }}>{`EA Funds`}</a>{`.`}</p>
    <h2 {...{
      "id": "conducting-research",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#conducting-research",
        "aria-label": "conducting research permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conducting research`}</h2>
    <p>{`As a research paradigm, longtermism is relatively new: foundational work remains to be done and there's still room for conceptual breakthroughs. In fact, it's hard to understate the value of careful and independent thinking about how to improve the long-run future. Figuring out ways to `}<em parentName="p">{`actually`}</em>{` make a positive difference is hard at the best of times, and especially when it comes to reasoning about the long-term future. Good ideas for practical projects are enormously valuable, but they're bottlenecked by good research.`}</p>
    <p>{`Longtermist-motivated research covers economics, philosophy, policial science, history, AI, law, and much more. Reading some research agendas could be a good way to get an impression of promising directions. You could take a look at the agendas from the `}<a parentName="p" {...{
        "href": "https://www.legalpriorities.org/research.html"
      }}>{`Legal Priorities Project`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://globalprioritiesinstitute.org/research-agenda/"
      }}>{`Global Priorities Institute`}</a>{`, and especially the `}<a parentName="p" {...{
        "href": "https://www.forethought.org/longtermism"
      }}>{`Forethought Foundation`}</a>{`.`}</p>
    <p>{`If you’re a student, check out the `}<a parentName="p" {...{
        "href": "https://effectivethesis.org/"
      }}>{`Effective Thesis`}</a>{` project — they provide free coaching and guidance to students, from undergraduate to PhD level, who want to begin research careers that significantly improve the world; including by longtermist-oriented work.`}</p>
    <h2 {...{
      "id": "donating",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#donating",
        "aria-label": "donating permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Donating`}</h2>
    <p>{`Many longtermist cause areas appear to be most constrained by skilled people, and by ideas for ambitious, scalable projects. But you can still make a difference with your money, as well as your time.`}</p>
    <p>{`One donation option is the `}<a parentName="p" {...{
        "href": "https://www.givingwhatwecan.org/charities/longtermism-fund"
      }}>{`Longtermism Fund`}</a>{` at `}<a parentName="p" {...{
        "href": "https://www.givingwhatwecan.org"
      }}>{`Giving What We Can`}</a>{`. The Longtermism Fund supports organisations that work to reduce the global catastrophic and existential risks that face humanity, as well as work that improves, promotes, and implements `}<a parentName="p" {...{
        "href": "https://www.longtermism.com/"
      }}>{`longtermist`}</a>{` ideas. The grants it makes are decided by expert grantmakers at `}<a parentName="p" {...{
        "href": "https://www.longview.org/"
      }}>{`Longview Philanthropy`}</a>{`, with input from the research team at Giving What We Can. You can learn more about the Longtermism Fund by `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/f7qAfcKArzYrBG7RB/announcing-the-longtermism-fund"
      }}>{`reading its announcement post`}</a>{`.`}</p>
    <p>{`You can also donate to the `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/funds/far-future"
      }}>{`Long-Term Future Fund`}</a>{`, part of `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/"
      }}>{`Effective Altruism Funds`}</a>{`. The LTFF makes small to medium grants — also to individuals and organizations that address global catastrophic risks or advocate for longtermist ideas. One difference between the LTFF and the Longtermism Fund is that the LTFF more often makes grants to somewhat less established or more speculative projects.`}</p>
    <p>{`Larger funds are not the only donation option. Indeed, there are some ways you could be `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/cjH2puDzAFrtrrThQ/despite-billions-of-extra-funding-small-donors-can-still#3__Small_donors_can_sometimes_beat_large_donors_in_terms_of_cost_effectiveness"
      }}>{`at an advantage`}</a>{` if you're a smaller donor. For instance, some organisations can’t accept large grants from foundations. One example is `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/Btm562wDNEuWXj9Gk/guarding-against-pandemics"
      }}>{`Guarding Against Pandemics`}</a>{` (GAP), which does non-partisan political advocacy for biosecurity work in the U.S.`}</p>
    <p>{`If you are a major philanthropist fortunate enough to consider donating a large amount of money, `}<a parentName="p" {...{
        "href": "https://www.longview.org/"
      }}>{`Longview Philanthropy`}</a>{` might be able to design and execute a bespoke giving strategy for you. Their help is free-of-charge, independent, and reviewed by external experts.`}</p>
    <p>{`You can also join a number of longtermists in making a lifelong commitment to helping others through effective giving by joining `}<a parentName="p" {...{
        "href": "https://www.givingwhatwecan.org/"
      }}>{`Giving What We Can`}</a>{`.`}</p>
    <LinkButton to='https://www.givingwhatwecan.org/' mdxType="LinkButton">Learn about <b>Giving What We Can</b></LinkButton>
    <p>{`Finally, many longtermists closely associate with the broader project of effective altruism, which aims to use reason and evidence to do the most good.`}</p>
    <LinkButton to='https://www.effectivealtruism.org/' mdxType="LinkButton">Learn about <b>effective altruism</b></LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      